import React from "react";
import { Row, Col,Button,Media, Image} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

import '../../assets/scss/showroom_chat.scss';

import sample_image from '../../assets/img/sample_image.png';

class Message extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){
        return(
        <Col>
        {this.props.conversation.map((message)=>{ 

            return(
            <Row key={message.created_at} className={this.props.current_memberid == message.sender ? "sc_content_out" : "sc_content_in"}>
                <Col xs={9} className="sc_content_bubble">
                    {/* Showroom / Product Related Display*/}
                    {message.title != null && (
                    <div className="sc_content_title">
                        {message.title}
                    </div>
                    )}

                    {/* Showroom Related Display*/}
                    {message.showroom != null && (
                    <Row className="sc_content_showroom">
                        <Col>
                            <Media className="sc_showroom_detail">
                                <Image src={sample_image} />
                                <Media.Body>
                                    <div>
                                        <span className="sc_showroom_type">
                                            Aseptic Packing
                                        </span>
                                        <h6>AIRplus® - Air Cushions for Working Comfort</h6>
                                    </div>
                                    <span>
                                        Storopack, solely distributed by Logipack Co., Ltd in Vietnam, offers the flexible protective packaging solutions. The air cushion formats AIRplus® Void, AIRplus® Cushion and AIRplus®
                                    </span>
                                </Media.Body>
                            </Media>
                        </Col>
                        <Col xs={1} className="sc_showroom_link">
                            <Button className="sc_showroom_btn" variant="link" size="xs"><FontAwesomeIcon icon={faChevronRight} /></Button>
                        </Col>
                    </Row>
                    )}

                    {/* Product Related Display*/}
                    {message.products!= null && (
                    <Row className="sc_content_product">
                        <Col className="sc_product_detail" xs={12} sm={4} md={3}>
                            <div>
                                <Image src={sample_image} />
                                <div className="sc_product_body">
                                    <span className="sc_product_type">
                                        Aseptic Packing
                                    </span>
                                    <h6>AIRplus® - Air Cushions for Working Comfort</h6>
                                </div>    
                            </div>
                            <div className="sc_product_link">
                                <Button className="sc_product_btn" variant="link" size="xs"><FontAwesomeIcon icon={faChevronRight} /></Button>
                            </div>
                        </Col>
                        <Col className="sc_product_detail" xs={12} sm={4} md={3}>
                            <div>
                                <Image src={sample_image} />
                                <div className="sc_product_body">
                                    <span className="sc_product_type">
                                        Aseptic Packing
                                    </span>
                                    <h6>AIRplus® - Air Cushions for Working Comfort</h6>
                                </div>    
                            </div>
                            <div className="sc_product_link">
                                <Button className="sc_product_btn" variant="link" size="xs"><FontAwesomeIcon icon={faChevronRight} /></Button>
                            </div>
                        </Col>
                        <Col className="sc_product_detail" xs={12} sm={4} md={3}>
                            <div>
                                <Image src={sample_image} />
                                <div className="sc_product_body">
                                    <span className="sc_product_type">
                                        Aseptic Packing
                                    </span>
                                    <h6>AIRplus® - Air Cushions for Working Comfort</h6>
                                </div>    
                            </div>
                            <div className="sc_product_link">
                                <Button className="sc_product_btn" variant="link" size="xs"><FontAwesomeIcon icon={faChevronRight} /></Button>
                            </div>
                        </Col>
                        <Col className="sc_product_detail" xs={12} sm={4} md={3}>
                            <div>
                                <Image src={sample_image} />
                                <div className="sc_product_body">
                                    <span className="sc_product_type">
                                        Aseptic Packing
                                    </span>
                                    <h6>AIRplus® - Air Cushions for Working Comfort</h6>
                                </div>    
                            </div>
                            <div className="sc_product_link">
                                <Button className="sc_product_btn" variant="link" size="xs"><FontAwesomeIcon icon={faChevronRight} /></Button>
                            </div>
                        </Col>
                    </Row>
                    )}

                    {/* Messages */}
                    {message.message != null && (
                    <div>
                        {message.message}
                    </div>
                    )}
                    <div className="sc_content_time">
                        <Moment unix local format="YYYY-MM-DD HH:mm">{message.created_at}</Moment>
                    </div>
                </Col>
            </Row>
            )
        })}
        </Col>
        );
    }
}

export default Message;