import React from "react";
import { Row, Col,Media} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

import '../../assets/scss/showroom_chat.scss';

import default_logo from '../../logo.svg';

class Chatroom extends React.Component {
    constructor(props) {
        super(props)
    }

    /*
    Note:
    class trigger active not work.
     */
    componentDidUpdate (){
        
    }
    render(){
        return(
            <Row className={"sc_contact "+(this.props.current_chatroom === this.props.room_id?"active":"")} onClick={() => this.props.onClick()}>
                <Col>
                    <Media className="sc_contact_item">
                        <img src={this.props.target_member.company_icon == null ? default_logo : this.props.target_member.company_icon} />
                        <Media.Body>
                            <h6>
                                {this.props.title}
                            </h6>
                            <span>
                                {this.props.title}
                            </span>
                        </Media.Body>
                    </Media>
                </Col>
                <Col xs={4} className="sc_contact_time">
                    {(this.props.last_seen == null || this.props.last_seen < this.props.last_message) && <span className="sc_contact_new">new</span> }
                    <span><Moment unix local format="YYYY-MM-DD HH:mm">{this.props.last_message}</Moment></span>
                </Col>
            </Row>

        );
    }
}

export default Chatroom;