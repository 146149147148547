import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { createStore } from "redux";

import ShowroomChat from './views/ShowroomChat.jsx';

//scss
import 'bootstrap/dist/css/bootstrap.min.css';


var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
    <Route path="/:user_id" component={ShowroomChat} />
    <Route path="/" component={ShowroomChat} />
    
    </Switch>
  </Router>
  ,
  document.getElementById('root')
);

reportWebVitals();
