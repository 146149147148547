import React from "react";
import {Container, Row, Col,InputGroup,FormControl,Button,Media} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,faAngleLeft, faStoreAlt, faPaperPlane, faChevronLeft } from '@fortawesome/free-solid-svg-icons';


import Chatroom from './ShowroomChat/Chatroom';
import Message from './ShowroomChat/Message';
import '../assets/scss/showroom_chat.scss';

import default_logo from '../logo.svg';


const testing_id = {
    188:"eb5404d5-4899e043-ceaf41e5-630b6bc4-2f39faab",
    199: "325c1982-15fae46a-4b56ce53-7d79d8ec-18c9c3f3",
    1:"14815e07-648cdcbc-5afab33f-32e4b5f9-ca1468c0",
    2:"c86c89eb-d6fd9f4a-1c3a64f3-8739a7f6-5cfbd7bc",
  }

class ShowroomChat extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            matchesSize: window.matchMedia("(min-width: 768px)").matches ,
            content_active:false,
            chatroom:true,
            current_chatroom:0,
            rooms:[],
            current_memberid : 0,
            target_id:0,
            target_name:"",
            target_image:null,
            target_seen: null,
            inputMessage: "",
            conversation:[],
        };
        //this.handleLoad = this.handleLoad.bind(this);
    }
    componentWillMount(){
        
    }
    componentDidMount() {
        //window.addEventListener('load', this.handleLoad);
        //setTimeout(() => this.setState({showHeader: true}), 2000);
        let user_id = this.props.match.params.user_id;
        
        this.getChatroomList(user_id)
     }
    componentDidUpdate (){
        //Did update after read message
    }
     componentWillUnmount() { 
       //window.removeEventListener('load', this.handleLoad)  
     }

    async getChatroomList(user_id){
        this.setState({ current_memberid: user_id });
        const response = await fetch('https://api.showroom-staging.dbaa.pro/store/'+user_id+'/chatrooms?page=1&size=10',{
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + testing_id[user_id],
                'Content-Type': 'application/json'
            }),
        });
        const json = await response.json();
        //console.log(json);
        this.setState({ rooms: json.result.rooms });
    }

    ChatroomClick = async (id, target_member, last_seen) =>{
        this.setState({
            current_chatroom:id
        })
        
        //console.log(target_member)

        this.setState({
            target_id:target_member.id,
            target_name: target_member.name,
            target_image: (target_member.company_icon == null ? default_logo : target_member.company_icon),
            target_seen: last_seen
        })
        console.log("roomclick:"+id)
        await this.renderMessage(id)

    
        this.updateRead();
        console.log("update seen")
    
        this.toggleContent();
        await this.getChatroomList(this.state.current_memberid);
    }
    async renderMessage(id){
        
        try{
            const response = await fetch('https://api.showroom-staging.dbaa.pro​/store/'+this.state.current_memberid+'/chatroom/'+id+'/messages',{
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'Bearer ' + testing_id[this.state.current_memberid],
                    'Content-Type': 'application/json'
                }),
            });
            const json = await response.json()
            const jsonResult = json.result
            
            this.setState({ conversation: jsonResult.messages });
        }
        catch (err){
            console.log(err)
        }
     }

    async updateRead(){
        
        const response = await fetch('https://api.showroom-staging.dbaa.pro/store/'+this.state.current_memberid+'/chatroom/'+this.state.current_chatroom+'/seen',{
            method: 'PUT',
            headers: new Headers({
                'Authorization': 'Bearer ' + testing_id[this.state.current_memberid],
                'Content-Type': 'application/json'
            }),
        });
        const json = await response.json();
        //console.log(json);
     }

    messageChange = () => {
        this.getChatroomList()
    }

    SendMessage = () => {
        if(this.state.inputMessage != null && this.state.inputMessage != ""){

            this.postMessage();

            this.setState({inputMessage: ""});
        }
    }
    async postMessage(){
        
        const response = await fetch('https://api.showroom-staging.dbaa.pro​/sender/'+this.state.current_memberid+'/receiver/'+this.state.target_id+'/message',{
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + testing_id[this.state.current_memberid],
                'Content-Type': 'application/json'
            }),
            body:JSON.stringify({
                'message' : this.state.inputMessage,
            })
        });
        const json = await response.json();
        
        
        await this.renderMessage(this.state.current_chatroom)
    }
    
    toggleContent = () => {
        this.setState({
            content_active:!this.state.content_active
        })
        if(!this.state.matchesSize){
            this.setState({chatroom:!this.state.chatroom})
        }
    }
    render(){
        return(
            <Container className="sc_container">
                <Row className="sc_header" >
                    <Col xs={1} md={3}>
                        <Button className="sc_header_backbtn" variant="light" size="md"><FontAwesomeIcon icon={faAngleLeft} />{this.state.matchesSize && ( <> MEMBER DASHBOARD</>)}</Button>
                    </Col>
                    <Col className="sc_header_title">
                     <h2>MESSAGE</h2>
                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>
                <Row className="sc_chat">
                    {this.state.chatroom && (
                    <Col md={4} className="sc_chat_rooms">
                        
                        <Row>
                            <Col className="sc_search">
                                <InputGroup md={3} className="sc_search_box">
                                    <InputGroup.Prepend>
                                        <Button className="sc_search_btn" variant="outline-secondary"><FontAwesomeIcon icon={faSearch} /></Button>
                                    </InputGroup.Prepend>
                                    <FormControl className="sc_search_input" placeholder="Search" aria-describedby="basic-addon1" />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="sc_contacts">
                            <Col>
                            
                            {this.state.rooms.map((room) =>{
                                const target_index = room.members[0].id == this.state.current_memberid ? 1 :0;
                                return(
                                    <Chatroom key={"room_"+room.id} room_id={room.id} title={room.title} last_message={room.last_message} last_seen={room.last_seen} onClick={() => this.ChatroomClick(room.id, room.members[target_index], room.last_seen)} current_chatroom={this.state.current_chatroom} target_member={room.members[target_index]} />
                                    )
                            }
                            )}
                            </Col>
                        </Row>
                    </Col>
                    )}
                    {(this.state.matchesSize|| this.state.content_active) &&(
                    <Col md={8} className="sc_chat_content">
                        {(this.state.target_id != 0) && (
                        <>
                        <Row className="sc_content_header">
                            <Col>
                                {!this.state.matchesSize && (
                                    <Button className="sc_header_back" variant="link" size="xs" onClick={() => this.toggleContent()}><FontAwesomeIcon icon={faChevronLeft} /></Button>
                                )}
                                <Media className="sc_header_item">
                                    <img src={this.state.target_image} />
                                    <Media.Body>
                                        <h5>
                                            {this.state.target_name}
                                        </h5>
                                        <span>
                                        Macy Chan , Sourcing manager
                                        </span>
                                    </Media.Body>
                                </Media>
                            </Col>
                            <Col xs={2} md={3} className="sc_header_link">
                                <Button className="sc_header_showrrombtn" variant="link" size="xs"><FontAwesomeIcon icon={faStoreAlt} />{this.state.matchesSize && (<> SHOWROOM</>)}</Button>
                            </Col>
                        </Row>
                        <Row className="sc_content_msg">
                            <Message chatroom_id={this.state.current_chatroom}  current_memberid={this.state.current_memberid} target_id={this.state.target_id} onChange={() => this.messageChange()} seenStatus={this.state.target_seen} conversation={this.state.conversation}/>
                        </Row>
                        <Row className="sc_content_send">
                            <Col>
                                <InputGroup md={3} className="sc_send_box" hasValidation>
                                    <FormControl className="sc_send_input" placeholder="Your message ..." aria-describedby="basic-addon2" onChange={e => this.setState({inputMessage:e.target.value})} required  value={this.state.inputMessage} />
                                    <InputGroup.Append>
                                        <Button className="sc_send_btn" variant="outline-secondary" onClick={() => this.SendMessage()}><FontAwesomeIcon icon={faPaperPlane} /></Button>
                                    </InputGroup.Append>
                                    <FormControl.Feedback type="invalid">
                                        Please type some message first.
                                    </FormControl.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        </>
                        )}
                    </Col>
                    )}
                </Row>
            
            </Container>
        );
    }
}

export default ShowroomChat;